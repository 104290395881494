import { BrowserRouter, Route, Navigate } from 'react-router-dom';

import { CallbackPage } from '@/shared/pages/Callback';
import { ResearchPage } from '@/pageAI/pages/ResearchPage';
import { ClientsPage } from '@/pageAI/pages/ClientsPage';
import { ConversationPage } from '@/pageAI/pages/ConversationPage';
import { SummaryPage } from '@/pageAI/pages/SummaryPage';
import { PageAILayout } from '@/pageAI/components/layout/PageAILayout';
import { ClientDetailsPage } from '@/pageAI/pages/ClientDetailsPage';
import { SettingsPage } from '@/pageAI/pages/SettingsPage';
import { NotFoundPage } from '@/pageAI/pages/NotFoundPage';
import { ProtectedRoute } from './ProtectedRoute';
import { AllowedRoute } from './AllowedRoute';
import { OrganizationPage } from '../pages/OrganizationPage';
import { UserOnboardingPage } from '../pages/UserOnboardingPage';
import { OrganizationOnboardingPage } from '../pages/OrganizationOnboardingPage';
import { SignUpPage } from '../pages/SignUpPage';
import { AcceptInvitationPage } from '../pages/AcceptInvitationPage';
import { SentryRoutes } from '@/shared/plugins/sentry';
import { SummaryPageRedirection } from '../pages/SummaryPageRedirection';
import { UsageBillingPage } from '../pages/UsageBillingPage';

export const PageAIAppRouter = () => {
  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route element={<ProtectedRoute component={AllowedRoute} />}>
          <Route path="/organization-onboarding" element={<OrganizationOnboardingPage />} />

          <Route path="/user-onboarding" element={<UserOnboardingPage />} />

          <Route path="/" element={<PageAILayout />}>
            <Route path="/" element={<Navigate replace to="/clients" />} />

            <Route path="/clients" element={<ClientsPage />} />

            <Route path="/research" element={<ResearchPage />} />

            <Route path="/research/:conversationId" element={<ConversationPage />} />

            <Route path="/chat" element={<ResearchPage />} />

            <Route path="/chat/:conversationId" element={<ConversationPage />} />

            <Route path="/clients/:clientId" element={<ClientDetailsPage />} />

            <Route path="/clients/:clientId/summaries/:summaryType" element={<SummaryPageRedirection />} />

            <Route path="/clients/:clientId/summaries" element={<SummaryPage />} />

            <Route path="/organizations/:organizationId/usage-billing" element={<UsageBillingPage />} />

            <Route path="/organizations/:organizationId" element={<OrganizationPage />} />

            <Route path="/settings" element={<SettingsPage />} />
          </Route>
        </Route>

        <Route path="/sign-up" element={<SignUpPage />} />

        <Route path="/accept-invitation" element={<AcceptInvitationPage />} />

        <Route path="/callback" element={<CallbackPage />} />

        <Route path="*" element={<NotFoundPage />} />
      </SentryRoutes>
    </BrowserRouter>
  );
};
