import { useMutation } from '@tanstack/react-query';

import { createCreditPurchaseSessionMutation } from '@/pageAI/api/billing';
import { CreateCreditPurchaseSessionInput, CreateCreditPurchaseSessionMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';

export const useCreateCreditPurchaseSession = () => {
  const { notify } = useNotifications();
  const { request } = useGraphQLRequest();

  const { mutateAsync: createCreditPurchaseSession, isLoading } = useMutation<
    CreateCreditPurchaseSessionMutation,
    Error,
    CreateCreditPurchaseSessionInput
  >({
    mutationFn: async (input) => {
      return request(createCreditPurchaseSessionMutation, { input });
    },
    onError: () => {
      notify('Error', 'Failed to create credit purchase session');
    },
  });

  return { createCreditPurchaseSession, isLoading };
};
