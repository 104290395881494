import { memo, useEffect, useState } from 'react';
import { ActionIcon, Flex, Text, Tooltip } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { IconInfoCircle, IconKeyboard } from '@tabler/icons-react';

import { isMacOS } from '@/shared/lib/browser';

interface ConditionSearchTermHintProps {}

const ConditionSearchTermHintBase = (props: ConditionSearchTermHintProps) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [hideConditionSearchTermHint, setHideConditionSearchTermHint] = useLocalStorage({
    key: 'hideConditionSearchTermHint',
    defaultValue: false,
  });

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (hideConditionSearchTermHint) return;

    const timeout = setTimeout(() => {
      setHideConditionSearchTermHint(true);
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [hideConditionSearchTermHint, setHideConditionSearchTermHint]);

  if (isFirstRender) return null;

  return (
    <Tooltip
      withArrow
      withinPortal
      {...(!hideConditionSearchTermHint ? { opened: !hideConditionSearchTermHint } : {})}
      onMouseEnter={() => setHideConditionSearchTermHint(true)}
      label={
        <Flex align="center" gap={6}>
          <IconKeyboard size={14} /> You can press and hold{' '}
          <Text
            component="strong"
            fz="0.75rem"
            sx={(theme) => ({
              padding: '2px 6px',
              background: theme.fn.lighten(theme.colors.dark[5], 0.11),
              borderRadius: 4,
            })}
          >
            {isMacOS() ? 'Cmd' : 'Ctrl'}
          </Text>{' '}
          to select multiple terms.
        </Flex>
      }
    >
      <ActionIcon size="xs">
        <IconInfoCircle size={14} />
      </ActionIcon>
    </Tooltip>
  );
};

export const ConditionSearchTermHint = memo(ConditionSearchTermHintBase);
