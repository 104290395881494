import { useQuery } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';
import { usageHistoryQuery } from '@/pageAI/api';
import { useMemo } from 'react';
import { getStartAndEndOfMonth } from '@/shared/utils/date';
import { truthy } from '@/shared/utils/boolean';

export const queryMap = {
  organization: {
    usageHistory: {
      queryKey: (organizationId: string, from: Date, to: Date) => [
        'organizations',
        organizationId,
        'usage-history',
        from,
        to,
      ],
      queryFn: async (request: GraphQLRequestFunction, organizationId: string, from: Date, to: Date) => {
        const responseBody = await request(usageHistoryQuery, { id: organizationId, from, to });

        const creditTransactions = responseBody.organization?.creditTransactions.nodes?.filter(truthy);
        const pageInfo = responseBody.organization?.creditTransactions.pageInfo;

        return { creditTransactions, pageInfo };
      },
    },
  },
};

export type UsageHistoryQuery = Awaited<ReturnType<typeof queryMap.organization.usageHistory.queryFn>>;

export interface UseUsageHistoryProps {
  organizationId: string;
  from?: Date;
  to?: Date;
}

export const useUsageHistory = ({ organizationId, from, to }: UseUsageHistoryProps) => {
  const { request } = useGraphQLRequest();

  const [fromDate, toDate] = useMemo(() => {
    if (from && to) return [from, to];

    return getStartAndEndOfMonth(new Date());
  }, [from, to]);

  const { data, isLoading, isError } = useQuery<UsageHistoryQuery>(
    queryMap.organization.usageHistory.queryKey(organizationId, fromDate, toDate),
    () => queryMap.organization.usageHistory.queryFn(request, organizationId, fromDate, toDate),
  );

  return { usageHistory: data?.creditTransactions, pageInfo: data?.pageInfo, isLoading, isError };
};
