import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Anchor, Avatar, Box, Flex, Loader, ScrollArea, SegmentedControl, Text } from '@mantine/core';

import { Panel } from '@/pageAI/designSystem/Panel';
import { withPageWrapper } from '@/shared/hocs/withPageWrapper';
import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';
import { PAGE_AI_HEADER_HEIGHT, PageAIHeader } from '@/pageAI/components/layout/PageAIHeader';
import { DataEmptyPlaceholder } from '@/pageAI/components/clients/DataEmptyPlaceholder';
import { useOrganization } from '@/pageAI/hooks/organizations/useOrganization';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import { getOrganizationDisplayName } from '@/pageAI/services/organizations';
import { UsageLayout } from '@/pageAI/components/usage/UsageLayout';
import { BillingLayout } from '@/pageAI/components/billing/BillingLayout';

const UsageBillingPageBase = () => {
  const { organizationId } = useParams();
  const { organization, isLoading, isError } = useOrganization(organizationId);
  const { currentUser } = useCurrentUser();
  const [tab, setTab] = useState('Usage');

  const currentMember = useMemo(
    () => organization?.members.find((member) => member.user.id === currentUser?.id),
    [organization, currentUser],
  );

  const organizationDisplayName = organization ? getOrganizationDisplayName(organization) : 'Organization';

  const items = useMemo(
    () =>
      organization
        ? [
            <Anchor key="icon" to={`/organizations/${organizationId}`} component={Link}>
              <Flex align="center" gap={6}>
                <Avatar src={organization?.avatarUrl} alt={organizationDisplayName} radius="50%" size={24} />

                <Text color="dark" fw={500}>
                  {organizationDisplayName}
                </Text>
              </Flex>
            </Anchor>,
            <Anchor key="title" to={window.location.href} component={Link} fw={600}>
              Usage & Billing
            </Anchor>,
          ]
        : [],
    [organization, organizationDisplayName, organizationId],
  );

  if (isError || (!isLoading && !currentMember))
    return (
      <Flex sx={{ width: '100%', height: '100%' }} align="center" justify="center">
        <ErrorPlaceholder />
      </Flex>
    );

  const renderContent = () => {
    if (isLoading)
      return (
        <DataEmptyPlaceholder
          icon={<Loader size={40} />}
          title="Loading usage & billing..."
          description="Please wait while the usage & billing info of your organization is being loaded."
        />
      );

    if (!organization || !currentMember)
      return (
        <DataEmptyPlaceholder
          title="No organization found"
          description="The organization you are looking for does not exist."
        />
      );

    if (tab === 'Billing') {
      return <BillingLayout organization={organization} />;
    }

    return <UsageLayout organization={organization} />;
  };

  return (
    <Flex direction="column">
      <PageAIHeader
        headerItems={items}
        headerSuffix={
          <Flex ml="xs">
            <SegmentedControl
              value={tab}
              onChange={(value) => setTab(value)}
              data={['Usage', 'Billing']}
              size="xs"
              sx={{ '.ghost-SegmentedControl-control': { width: 120 } }}
            />
          </Flex>
        }
      />

      <ScrollArea sx={{ height: `calc(100vh - ${PAGE_AI_HEADER_HEIGHT}px)`, overflow: 'auto' }}>
        <Box p="md" pt={4}>
          <Panel
            sx={{
              width: '100%',
              height: 'fit-content',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              padding: 16,
              gap: 4,
            }}
          >
            {renderContent()}
          </Panel>
        </Box>
      </ScrollArea>
    </Flex>
  );
};

export const UsageBillingPage = withPageWrapper(UsageBillingPageBase);
