import { Client } from '@/pageAI/@types';
import { FileAsset } from '@/shared/@types';
import { CaseTimelineStatus, SummaryByConditionStatus } from '@/pageAI/gql/graphql';
import { getClientFiles, quickFindFileAsset } from '../clients';
import { extractHrefFromMarkdown, extractHrefPartsFromMarkdown } from '@/shared/utils/markdown';
import { matchWords } from '@/shared/utils/string';
import { truthy } from '@/shared/utils/boolean';

export const SUMMARY_STATUS_COLOR_MAPPING = {
  NOT_GENERATED: 'brand',
  GENERATED: 'teal.7',
  SCHEDULED: 'orange',
  PENDING: 'orange',
  RUNNING: 'orange',
  POSTPROCESSING: 'orange',
  PENDING_REVIEW: 'orange',
  IN_REVIEW: 'orange',
  ERROR: 'red',
  CANCELED: 'red',
};

export const SUMMARY_STATUS_TRANSLATION_MAPPING = {
  NOT_GENERATED: 'Not Generated',
  GENERATED: 'Generated',
  PENDING: 'Pending',
  RUNNING: 'Running',
  POSTPROCESSING: 'Running',
  ERROR: 'Failed',
  CANCELED: 'Cancelled',
  PENDING_REVIEW: 'Verifying',
  IN_REVIEW: 'Verifying',
  SCHEDULED: 'Scheduled',
};

export const canDisplaySummaries = (client: Client) => {
  return (
    client.caseTimelineStatus !== CaseTimelineStatus.NotGenerated ||
    client.summaryByConditionStatus !== SummaryByConditionStatus.NotGenerated
  );
};

export const isSummaryEmpty = (summary?: string | null) => {
  return !summary || summary.trim() === '' || summary.trim() === 'N/A';
};

export const areGeneratingSummaries = (client: Pick<Client, 'caseTimelineStatus' | 'summaryByConditionStatus'>) => {
  return (
    [CaseTimelineStatus.Running, CaseTimelineStatus.Pending, CaseTimelineStatus.InReview].includes(
      client.caseTimelineStatus,
    ) ||
    [SummaryByConditionStatus.Running, SummaryByConditionStatus.Pending, SummaryByConditionStatus.InReview].includes(
      client.summaryByConditionStatus,
    )
  );
};

export const normalizeCaseTimelineItemReferencedTexts = (referencedTexts: string[]) => {
  let normalizedReferencedTexts: string[] = [];

  referencedTexts
    .map((text) => text.replace(/\n+/g, '\n').replace(/☐|☑/g, '').trim())
    .forEach((text) => {
      const splitTexts = text.split('\n').map((text) => text.trim());

      normalizedReferencedTexts = [...normalizedReferencedTexts, ...splitTexts];
    });

  return [...new Set(normalizedReferencedTexts.filter(truthy))];
};

export const extractFileIdAndPageIndexFromHref = (href: string) => {
  if (!href.startsWith('/file-preview')) return {};

  const [, , extractedFileId, , page] = href.split('/');

  const fileId = extractedFileId;
  const pageIndex = Number(page) - 1;

  return { fileId, pageIndex };
};

export const extractFileInfoFromHref = (fileAssets: FileAsset[] | undefined, href: string) => {
  const { fileId, pageIndex } = extractFileIdAndPageIndexFromHref(href);

  if (!fileId) return {};

  return { fileAsset: fileAssets ? quickFindFileAsset(fileAssets, fileId) : undefined, pageIndex, fileId };
};

export const extractFileInfoOfClientFromHref = (client: Client, href: string) => {
  const { fileAssets } = client ? getClientFiles(client) : { fileAssets: [] };

  return extractFileInfoFromHref(fileAssets, href);
};

export const extractPageNumbersFromReference = (reference: string) => {
  const startPage = reference.match(/pages (\d+)/)?.[1] || reference.match(/page (\d+)/)?.[1];
  const endPage = reference.match(/pages \d+-(\d+)/)?.[1];

  return { startPage: Number(startPage), endPage: Number(endPage) };
};

export const doesSummaryReferenceContainKeyword = (
  reference: string,
  keyword: string,
  clientFileAssets: FileAsset[] = [],
  shouldMatchWholeWords = false,
) => {
  if (!keyword) return true;

  const loweredCaseKeyword = keyword.toLowerCase().trim();
  const { fileAsset } = extractFileInfoFromHref(clientFileAssets, extractHrefFromMarkdown(reference));

  if (!fileAsset?.metadata?.pageAIMetadata) return false;

  const { vaFileTypeName, indexNumber } = fileAsset.metadata.pageAIMetadata;

  const loweredCaseCleanedFileName = vaFileTypeName.toLowerCase();
  const loweredCaseIndexNumber = indexNumber?.toLowerCase();

  return (
    matchWords(loweredCaseCleanedFileName, loweredCaseKeyword, shouldMatchWholeWords) ||
    (loweredCaseIndexNumber && loweredCaseKeyword.includes(loweredCaseIndexNumber)) ||
    matchWords(`[${loweredCaseIndexNumber}]`, loweredCaseKeyword, shouldMatchWholeWords)
  );
};

export const addFileIndexToEventReference = (reference: string, clientFileAssets: FileAsset[] = []) => {
  const { displayText, href } = extractHrefPartsFromMarkdown(reference);
  const { fileAsset } = extractFileInfoFromHref(clientFileAssets, href);

  if (!fileAsset?.metadata?.pageAIMetadata) return reference;

  const { indexNumber } = fileAsset.metadata.pageAIMetadata;

  const indexNumberInBrackets = `[${indexNumber}]`;

  return `[${displayText} ${indexNumberInBrackets}](${href}) `;
};

export const getPageDisplayTextFromReference = (reference: string, pageNumberOffset = 1) => {
  const { startPage, endPage } = extractPageNumbersFromReference(reference);
  const offset = pageNumberOffset - 1;

  if (!startPage && !endPage) return '';

  if ((startPage && !endPage) || startPage === endPage) return `page ${startPage + offset}`;

  return `pages ${startPage + offset}-${endPage + offset}`;
};
