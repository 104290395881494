import { memo, useState } from 'react';
import { Button, clsx, Flex, List, Radio, Text, ThemeIcon } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';

interface PricingCardProps {
  title?: string;
  description?: string;
  creditsSelectable?: boolean;
  price?: React.ReactNode;
  pricePerCredit?: number;
  originalPricePerCredit?: number;
  priceSuffix?: string;
  features?: string[];
  buttonText?: string;
  onPurchase?: (numberOfCredits: number) => void;
  className?: string;
  inverse?: boolean;
  loading?: boolean;
  disabled?: boolean;
  aboveButtonSection?: React.ReactNode;
}

const PricingCardBase = ({
  title = 'One-time purchase',
  description = 'A bundle of credits to use for your organization',
  creditsSelectable = true,
  pricePerCredit,
  originalPricePerCredit,
  price: priceText = '$150',
  priceSuffix,
  features = [
    'Unlimited clients',
    'Comprehensive case timeline',
    'Summaries for all conditions',
    'Powerful content search',
  ],
  buttonText = 'Purchase',
  className,
  inverse = false,
  loading = false,
  disabled = false,
  aboveButtonSection,
  onPurchase,
}: PricingCardProps) => {
  const [numberOfCredits, setNumberOfCredits] = useState(10);

  const handleChangeNumberOfCredits = (value: string) => {
    setNumberOfCredits(Number(value));
  };

  const handlePurchase = () => {
    onPurchase?.(numberOfCredits);
  };

  const price = pricePerCredit ? `$${pricePerCredit * numberOfCredits}` : priceText;
  const originalPrice = originalPricePerCredit ? originalPricePerCredit * numberOfCredits : null;
  const isDiscounted = originalPricePerCredit && pricePerCredit && pricePerCredit < originalPricePerCredit;
  const originalPriceText = originalPrice ? `$${originalPrice}` : null;

  return (
    <Flex
      className={clsx('pricing-card', className)}
      direction="column"
      justify="center"
      pt={48}
      pb={40}
      px={40}
      sx={(theme) => ({
        height: 'fit-content',
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: 4,
        background: inverse ? theme.colors.dark[5] : theme.white,
      })}
    >
      <Text fz="1.125rem" fw={500} color={inverse ? 'white' : 'dark'}>
        {title}
      </Text>

      <Text fz="0.75rem" fw={500} color={inverse ? 'gray.2' : 'gray.6'}>
        {description}
      </Text>

      <Text fz="1.75rem" fw={600} color={isDiscounted ? 'teal.4' : inverse ? 'white' : 'dark'} mt="xl">
        {isDiscounted && (
          <>
            <Text component="span" color={inverse ? 'gray.2' : 'gray.6'} sx={{ textDecoration: 'line-through' }}>
              {originalPriceText}
            </Text>
            &nbsp;
          </>
        )}

        {price}

        {priceSuffix && (
          <Text component="span" fz="0.875rem" fw={600} color={inverse ? 'gray.2' : 'gray.6'}>
            {priceSuffix}
          </Text>
        )}
      </Text>

      {pricePerCredit && (
        <Text fz="0.75rem" color={inverse ? 'gray.2' : 'gray.6'} mt={-4}>
          ${pricePerCredit} per credit
        </Text>
      )}

      <List
        mt="xl"
        spacing="xs"
        size="sm"
        center
        icon={
          <ThemeIcon size={20} radius="xl" color={inverse ? 'white' : 'dark'}>
            <IconCheck size={12} strokeWidth={3} color={inverse ? 'black' : 'white'} />
          </ThemeIcon>
        }
      >
        {features.map((feature, index) => (
          <List.Item key={index}>
            <Text color={inverse ? 'white' : 'dark'}>{feature}</Text>
          </List.Item>
        ))}
      </List>

      {creditsSelectable && (
        <Flex
          p="md"
          mt="xl"
          sx={(theme) => ({
            borderRadius: 4,
            ...(inverse
              ? {
                  border: `1px solid ${theme.white}`,
                  '.ghost-RadioGroup-label': {
                    color: theme.white,
                  },
                  '*:not(.ghost-RadioGroup-required)': {
                    color: theme.white,
                  },
                }
              : {
                  border: `1px solid ${theme.colors.gray[2]}`,
                }),
          })}
        >
          <Radio.Group
            label="Select your credit bundle"
            withAsterisk
            value={String(numberOfCredits)}
            onChange={handleChangeNumberOfCredits}
          >
            <Flex direction="column" gap="xs" mt="xs">
              <Radio value="10" label="10 credits (save 4%)" color={inverse ? 'teal' : 'dark'} />
              <Radio value="25" label="25 credits (save 8%)" color={inverse ? 'teal' : 'dark'} />
              <Radio value="50" label="50 credits (save 12%)" color={inverse ? 'teal' : 'dark'} />
              <Radio value="100" label="100 credits (save 15%)" color={inverse ? 'teal' : 'dark'} />
            </Flex>
          </Radio.Group>
        </Flex>
      )}

      {aboveButtonSection}

      <Button
        variant={inverse ? 'filled' : 'outline'}
        mt={creditsSelectable ? 'xl' : 80}
        size="md"
        onClick={handlePurchase}
        loading={loading}
        disabled={disabled}
        sx={(theme) => ({
          ...(inverse
            ? {
                background: theme.white,
                color: theme.colors.dark[5],
                transition: 'all 0.15s ease-in-out',
                svg: {
                  stroke: theme.colors.dark[2],
                },
                '&:hover': {
                  background: theme.colors.gray[1],
                },
              }
            : {}),
        })}
      >
        {buttonText}
      </Button>
    </Flex>
  );
};

export const PricingCard = memo(PricingCardBase);
