import React, { memo } from 'react';
import { Accordion, ActionIcon, Flex, Paper, Sx, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconEye, IconEyeOff } from '@tabler/icons-react';

import { ImportantInfoItem, ImportantInfoRow } from '../ImportantInfoRow';

interface ImportantInfoCardProps {
  items: ImportantInfoItem[];
  title?: React.ReactNode;
  toggleable?: boolean;
  sx?: Sx;
  className?: string;
  action?: React.ReactNode;
}

const ImportantInfoCardBase = ({ items, title, toggleable = false, sx, className, action }: ImportantInfoCardProps) => {
  const [opened, { toggle }] = useDisclosure(!toggleable);

  return (
    <Paper withBorder p="md" radius={4} className={className} sx={sx}>
      <Flex direction="column">
        {title && (
          <Flex align="center" justify="space-between" gap="xl">
            <Flex align="center" gap={4}>
              <Text fw={600} color="dark.4" sx={{ whiteSpace: 'nowrap' }}>
                {title}
              </Text>

              {toggleable && (
                <>
                  {opened ? (
                    <ActionIcon onClick={toggle}>
                      <IconEyeOff size={16} />
                    </ActionIcon>
                  ) : (
                    <ActionIcon onClick={toggle}>
                      <IconEye onClick={toggle} size={16} />
                    </ActionIcon>
                  )}
                </>
              )}
            </Flex>

            {action}
          </Flex>
        )}

        <Accordion
          value={opened ? 'default' : ''}
          sx={{
            '.ghost-Accordion-content': {
              padding: 0,
            },
          }}
        >
          <Accordion.Item value="default" sx={{ border: 'none' }}>
            <Accordion.Panel>
              <Flex sx={{ flexWrap: 'wrap' }} gap="xs" mt={toggleable ? 'xs' : 0}>
                {items.map((item) => (
                  <ImportantInfoRow item={item} key={String(item.label)} />
                ))}
              </Flex>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Flex>
    </Paper>
  );
};

export const ImportantInfoCard = memo(ImportantInfoCardBase);
