import { memo, useMemo } from 'react';
import { IconShare } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';

import { ShareFileButtonProps } from './ShareFileButton.types';
import { CopyButton } from '@/shared/components/common/CopyButton';
import { posthog } from '@/shared/plugins/posthog';

const ShareFileButtonBase = ({ fileAsset }: ShareFileButtonProps) => {
  const [searchParams] = useSearchParams();

  const shareUrl = useMemo(() => {
    searchParams.set('fileId', fileAsset.id);

    return [window.location.href.split('?')[0], searchParams.toString()].join('?');
  }, [searchParams, fileAsset.id]);

  const handleClick = () => {
    posthog.capture('[Files] Share file', {
      fileId: fileAsset.id,
      fileName: fileAsset.name,
      fileIndex: fileAsset.metadata?.pageAIMetadata?.vaFileTypeName,
    });
  };

  return (
    <CopyButton
      value={shareUrl}
      onClick={handleClick}
      tooltip="Share"
      copiedTooltip="Link copied"
      size="xs"
      icon={<IconShare size={14} />}
      testId="share-file"
    />
  );
};

export const ShareFileButton = memo(ShareFileButtonBase);
