import { memo } from 'react';
import { ActionIcon, Flex, Tooltip } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IconArrowRight, IconCalendar } from '@tabler/icons-react';

import { resetUsageDates, setUsageFrom, setUsageTo, useUsageStore } from '@/pageAI/states/usage';

interface UsageDateInputsProps {}

const UsageDateInputsBase = (props: UsageDateInputsProps) => {
  const fromDate = useUsageStore((state) => state.from);
  const toDate = useUsageStore((state) => state.to);

  return (
    <Flex align="center" gap={4}>
      <Tooltip label="View current month" withArrow withinPortal>
        <ActionIcon onClick={resetUsageDates}>
          <IconCalendar size={16} />
        </ActionIcon>
      </Tooltip>

      <DateInput
        size="xs"
        value={fromDate}
        onChange={(newDate) => {
          if (newDate) setUsageFrom(newDate);
        }}
      />

      <Flex sx={(theme) => ({ color: theme.colors.gray[5] })}>
        <IconArrowRight size={16} />
      </Flex>

      <DateInput
        size="xs"
        value={toDate}
        onChange={(newDate) => {
          if (newDate) setUsageTo(newDate);
        }}
      />
    </Flex>
  );
};

export const UsageDateInputs = memo(UsageDateInputsBase);
