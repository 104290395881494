import { memo, useEffect, useMemo } from 'react';
import { Button, Flex, Skeleton, Text } from '@mantine/core';
import { IconCash, IconReceipt2 } from '@tabler/icons-react';

import { Organization } from '@/pageAI/@types';
import { StatsCard } from '@/shared/designSystem/StatsCard';
import { CurrentPlanCard } from '../../billing/CurrentPlanCard';
import { UsageHistory } from '../UsageHistory';
import { useUsageHistory } from '@/pageAI/hooks/usage/useUsageHistory';
import { getUsageStatistics } from '@/pageAI/services/usage';
import { UsageDateInputs } from '../UsageDateInputs';
import { setCurrentPeriodCreditsUsed, useUsageStore } from '@/pageAI/states/usage';
import { openPurchaseModal } from '../../billing/PurchaseModal';

interface UsageLayoutProps {
  organization: Organization;
}

const UsageLayoutBase = ({ organization }: UsageLayoutProps) => {
  const fromDate = useUsageStore((state) => state.from);
  const toDate = useUsageStore((state) => state.to);
  const { usageHistory, isLoading, isError } = useUsageHistory({
    organizationId: organization.id,
    from: fromDate,
    to: toDate,
  });

  const { totalCreditsUsed } = useMemo(() => getUsageStatistics(usageHistory || []), [usageHistory]);

  useEffect(() => {
    setCurrentPeriodCreditsUsed(totalCreditsUsed);
  }, [totalCreditsUsed]);

  const statsCards = (
    <Flex
      gap="xl"
      sx={{
        width: '100%',
        '.stats-card': {
          width: '100%',
          flexGrow: 1,
        },
      }}
    >
      <StatsCard
        title="Credit balance"
        value={organization.creditsBalance}
        Icon={IconCash}
        description={
          <Flex justify="space-between">
            available summary generations
            <Button size="xs" mt={-12} onClick={openPurchaseModal}>
              Buy more
            </Button>
          </Flex>
        }
      />

      <StatsCard
        title="Credits used"
        value={isLoading ? <Skeleton h={24} w={32} /> : totalCreditsUsed}
        Icon={IconReceipt2}
        description="summary generated within this period"
      />
    </Flex>
  );

  const firstSection = (
    <Flex direction="column" gap="sm">
      <Flex gap="xs" align="center" justify="space-between">
        <Text fw={600} color="dark.4" sx={{ whiteSpace: 'nowrap' }}>
          Usage by Period
        </Text>

        <UsageDateInputs />
      </Flex>

      {statsCards}
    </Flex>
  );

  return (
    <Flex direction="column" sx={{ width: '100%' }} gap={24}>
      <CurrentPlanCard organization={organization} />

      {firstSection}

      <UsageHistory organization={organization} />
    </Flex>
  );
};

export const UsageLayout = memo(UsageLayoutBase);
