import { useQuery } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';
import { invoicesQuery } from '@/pageAI/api/billing';
import { truthy } from '@/shared/utils/boolean';

export const queryMap = {
  organization: {
    invoices: {
      queryKey: (organizationId: string) => ['organizations', organizationId, 'invoices'],
      queryFn: async (request: GraphQLRequestFunction, organizationId: string) => {
        const responseBody = await request(invoicesQuery, { id: organizationId });

        return {
          invoices: responseBody.organization?.invoices.nodes?.filter(truthy),
          pageInfo: responseBody.organization?.invoices.pageInfo,
        };
      },
    },
  },
};

export type InvoicesQuery = Awaited<ReturnType<typeof queryMap.organization.invoices.queryFn>>;

export interface UseInvoicesProps {
  organizationId: string;
}

export const useInvoices = ({ organizationId }: UseInvoicesProps) => {
  const { request } = useGraphQLRequest();

  const { data, isLoading, isError } = useQuery<InvoicesQuery>(
    queryMap.organization.invoices.queryKey(organizationId),
    () => queryMap.organization.invoices.queryFn(request, organizationId),
  );

  return { invoices: data?.invoices, isLoading, isError };
};
