import React, { memo } from 'react';
import { Flex, Text } from '@mantine/core';

export interface ImportantInfoItem {
  icon: React.ElementType;
  label: React.ReactNode;
  value: React.ReactNode;
  subValue?: React.ReactNode;
  className?: string;
}

interface ImportantInfoRowProps {
  item: ImportantInfoItem;
}

const ImportantInfoRowBase = ({ item }: ImportantInfoRowProps) => {
  const Icon = item.icon;

  return (
    <Flex direction="column" className={item.className} sx={{ width: 'calc(50% - 8px)' }} gap={4}>
      <Text size="xs" color="dimmed" fw={700} transform="uppercase" className="card-label">
        {item.label}
      </Text>

      <Flex gap="xs" align="center">
        <Flex
          align="center"
          justify="center"
          className="card-icon"
          sx={(theme) => ({
            backgroundColor: theme.colors.gray[0],
            borderRadius: '50%',
            width: 48,
            height: 48,
            color: theme.colors.gray[6],
          })}
        >
          <Icon size={24} />
        </Flex>

        <Flex direction="column">
          <Text color="dark.3" fw={600} sx={{ lineHeight: 1 }} className="card-value">
            {item.value}
          </Text>

          {item.subValue && (
            <Text fz="xs" color="dimmed" className="card-sub-value">
              {item.subValue}
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const ImportantInfoRow = memo(ImportantInfoRowBase);
