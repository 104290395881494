import { memo } from 'react';
import { ActionIcon, Flex, Menu } from '@mantine/core';
import { IconDotsVertical, IconDownload, IconFileText, IconLetterCase, IconPrinter } from '@tabler/icons-react';

import { AssetSidebarActionMenuProps } from './AssetSidebarActionMenu.types';
import { FilePreviewTab, useFilePreviewStore } from '@/shared/states/filePreview';
import { downloadPdfEventBus } from './AssetSidebarActionMenu.utils';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { getPdfFilename, printPdf } from '@/shared/services/files';
import { posthog } from '@/shared/plugins/posthog';

const AssetSidebarActionMenuBase = ({ tab, onTabChange }: AssetSidebarActionMenuProps) => {
  const client = useSelectedClient();
  const { asset: fileAsset, additionalMenuItems } = useFilePreviewStore();

  const handleDownload = () => {
    if (!client || !fileAsset) return null;

    posthog.capture('[Files] Download file', {
      fileId: fileAsset.id,
      fileName: fileAsset.name,
      fileIndex: fileAsset.metadata?.pageAIMetadata?.vaFileTypeName,
      clientId: client.id,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
    });

    const clientDisplayName = getClientDisplayName(client);

    const filename = getPdfFilename(fileAsset, clientDisplayName);

    downloadPdfEventBus.publish({ asset: fileAsset, filename });
  };

  const handlePrint = async () => {
    if (!client || !fileAsset) return null;

    posthog.capture('[Files] Print file', {
      fileId: fileAsset.id,
      fileName: fileAsset.name,
      fileIndex: fileAsset.metadata?.pageAIMetadata?.vaFileTypeName,
      clientId: client.id,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
    });

    const fileAssetBlob = await fetch(fileAsset.url).then((r) => r.blob());

    printPdf(fileAssetBlob);
  };

  const handleChangeTab = (newTab: FilePreviewTab) => {
    posthog.capture('[Files] Change file preview tab', {
      fileId: fileAsset?.id,
      fileName: fileAsset?.name,
      fileIndex: fileAsset?.metadata?.pageAIMetadata?.vaFileTypeName,
      tab: newTab,
    });

    onTabChange(newTab);
  };

  return (
    <Flex align="center" gap={4}>
      <Menu width={200} shadow="md">
        <Menu.Target>
          <ActionIcon color="gray.6" size="xs">
            <IconDotsVertical size={14} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            icon={tab === FilePreviewTab.PDF ? <IconLetterCase size={14} /> : <IconFileText size={14} />}
            onClick={() => handleChangeTab(tab === FilePreviewTab.PDF ? FilePreviewTab.Text : FilePreviewTab.PDF)}
          >
            {tab === FilePreviewTab.PDF ? 'View text' : 'View PDF'}
          </Menu.Item>

          {tab === FilePreviewTab.PDF && (
            <>
              <Menu.Item icon={<IconDownload size={14} />} onClick={handleDownload}>
                Download
              </Menu.Item>

              <Menu.Item icon={<IconPrinter size={14} />} onClick={handlePrint}>
                Print
              </Menu.Item>
            </>
          )}

          {additionalMenuItems}
        </Menu.Dropdown>
      </Menu>
    </Flex>
  );
};

export const AssetSidebarActionMenu = memo(AssetSidebarActionMenuBase);
