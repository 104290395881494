import { Organization } from '@/pageAI/@types';

export const PLAN_NAME_COLOR_MAPPING: Record<string, string> = {
  Trial: 'orange',
  'Pay as you go': 'dark',
  'Pay-as-you-go': 'dark',
};

export const getCurrentPlanInfo = (organization: Organization) => {
  const subscription = organization.subscription;
  let planName = subscription?.plan.name || 'Trial';
  const pricePerCredit = (subscription?.plan.pricePerCredit || 17500) / 100;

  if (organization.autoRenewalSetting.enabled) {
    planName = `${organization.autoRenewalSetting.amount}-credit bundle (auto-renewal)`;
  }

  return {
    planName,
    pricePerCredit,
  };
};

export const getNextBillingInfo = (organization: Organization) => {
  const currentPeriodStart = organization.subscription?.currentPeriodStart;
  const currentPeriodEnd = organization.subscription?.currentPeriodEnd;

  return {
    currentPeriodStart,
    currentPeriodEnd,
  };
};
