import { create } from 'zustand';

import { getStartAndEndOfMonth } from '@/shared/utils/date';

interface UsageState {
  from: Date;
  to: Date;
  currentPeriodCreditsUsed?: number;
}

const getDefaultFromAndTo = () => {
  const [from, to] = getStartAndEndOfMonth(new Date());

  return { from, to };
};

const { from, to } = getDefaultFromAndTo();

export const useUsageStore = create<UsageState>(() => ({
  from,
  to,
}));

export const setUsageFrom = (from: Date) => useUsageStore.setState({ from });

export const setUsageTo = (to: Date) => useUsageStore.setState({ to });

export const setCurrentPeriodCreditsUsed = (currentPeriodCreditsUsed: number) => {
  useUsageStore.setState({ currentPeriodCreditsUsed });
};

export const resetUsageDates = () => {
  const { from, to } = getDefaultFromAndTo();

  useUsageStore.setState({ from, to });
};
