import { sumBy } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import {
  AssetItem,
  AssetType,
  BrowserFile,
  CollectionAsset,
  FileAsset,
  FileUploadStatus,
  UploadFileOptions,
} from '@/shared/@types';
import {
  File as RequestFile,
  FileIndexingStatus,
  FileOcrStatus,
  FileTranscriptionStatus,
  OcrMethod,
  ClientQuery,
  FileSplitStatus,
} from '@/pageAI/gql/graphql';
import { formatDateTime } from '@/shared/utils/date';
import { formatTime } from '@/shared/utils/time';
import { isFeatureFlagEnabled } from '../featureFlags';

import PhotoIcon from '@/pageAI/icons/photo.svg?react';
import PDFIcon from '@/pageAI/icons/pdf.svg?react';
import FolderIcon from '@/pageAI/icons/folder-basic.svg?react';
import { removeAllSpaces } from '@/shared/utils/string';
import { truthy } from '@/shared/utils/boolean';

export const PDF_HIGHLIGHTING_FLAG_STORAGE_KEY = 'pdf_highlighting_flag';
export const FILE_INDEXING_FLAG_STORAGE_KEY = 'file_indexing_flag';
export const RETRY_OCR_FLAG_STORAGE_KEY = 'retry_ocr';

export const extractFileUploadId = (text: string) => {
  const openingTag = '<UploadId>';
  const closingTag = '</UploadId>';

  const start = text.search(openingTag);
  const end = text.search(closingTag);

  return text.slice(start + openingTag.length, end);
};

export const normalizeFileCollection = (
  fileCollection: ClientQuery['client']['fileCollections']['nodes'][0],
): CollectionAsset => {
  const normalizedFiles = fileCollection.files?.nodes.map(normalizeFile);

  return {
    ...fileCollection,
    type: AssetType.COLLECTION,
    numberOfFiles: fileCollection.files?.totalCount || 0,
    size: sumBy(normalizedFiles, 'size'),
    files: normalizedFiles,
  };
};

export const normalizeFile = (file: Omit<RequestFile, 'permissions'>): FileAsset => {
  return {
    ...file,
    isImportant: undefined,
    status: {
      ...file.status,
      uploadStatus: file.status.uploadStatus as unknown as FileUploadStatus,
    },
    type: AssetType.FILE,
  };
};

export enum FileDisplayStatus {
  UPLOADING = 'Uploading',
  UPLOADED = 'Uploaded',
  SPLITTING = 'Splitting',
  OCR_PENDING = 'OCR Pending',
  OCR_IN_PROGRESS = 'OCR In Progress',
  OCR_FAILED = 'OCR Failed',
  INDEXING_PENDING = 'Indexing Pending',
  INDEXING_IN_PROGRESS = 'Indexing In Progress',
  INDEXING_FAILED = 'Indexing Failed',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  CORRUPTED = 'Unreadable',
}

export const FILE_DISPLAY_STATUS_COLOR_MAPPING = {
  Uploading: 'orange',
  Uploaded: 'teal',
  Processing: 'orange',
  Processed: 'blue',
  Completed: 'blue',
  Failed: 'red',
  COMPLETED: 'blue',
  FAILED: 'red',
  CORRUPTED: 'red',
  Corrupted: 'red',
  Unreadable: 'red',
  NOT_STARTED: 'gray',
  STARTED: 'orange',
  NOT_REQUIRED: 'gray',
  'OCR Pending': 'orange',
  'OCR In Progress': 'orange',
  'Indexing Pending': 'orange',
  'Indexing In Progress': 'orange',
  'Indexing Failed': 'red',
  'OCR Failed': 'red',
  Splitting: 'orange',
};

export const getFileDisplayStatus = (file: Pick<RequestFile, 'status'> | Pick<FileAsset, 'status'>) => {
  const isFileIndexingEnabled = isFeatureFlagEnabled(FILE_INDEXING_FLAG_STORAGE_KEY);

  if (file.status.uploadStatus === FileUploadStatus.UPLOADING) return FileDisplayStatus.UPLOADING;

  if (file.status.uploadStatus === FileUploadStatus.FAILED) return FileDisplayStatus.FAILED;

  if (file.status.uploadStatus === FileUploadStatus.UPLOADED) {
    if (file.status.splitStatus) {
      if (file.status.splitStatus === FileSplitStatus.Splitting) return FileDisplayStatus.SPLITTING;

      if (file.status.splitStatus === FileSplitStatus.Failed) return FileDisplayStatus.FAILED;
    }
  }

  if (!file.status.ocrStatus) return FileDisplayStatus.UPLOADED;

  if (file.status.ocrStatus === FileOcrStatus.NotStarted) return FileDisplayStatus.OCR_PENDING;

  if (file.status.ocrStatus === FileOcrStatus.Started) return FileDisplayStatus.OCR_IN_PROGRESS;

  if (file.status.indexingStatus === FileIndexingStatus.NotStarted) return FileDisplayStatus.INDEXING_PENDING;

  if (file.status.indexingStatus === FileIndexingStatus.Started) return FileDisplayStatus.INDEXING_IN_PROGRESS;

  if (isFileIndexingEnabled) {
    if (file.status.indexingStatus === FileIndexingStatus.Failed) return FileDisplayStatus.INDEXING_FAILED;

    if (file.status.ocrStatus === FileOcrStatus.Failed) return FileDisplayStatus.OCR_FAILED;
  }

  if (file.status.ocrStatus === FileOcrStatus.Failed) {
    return FileDisplayStatus.OCR_FAILED;
  }

  if (file.status.ocrStatus === FileOcrStatus.Corrupted) {
    return FileDisplayStatus.CORRUPTED;
  }

  if (file.status.transcriptionStatus === FileTranscriptionStatus.Failed) {
    return FileDisplayStatus.FAILED;
  }

  if (
    file.status.indexingStatus === FileIndexingStatus.Completed ||
    file.status.ocrStatus === FileOcrStatus.Completed ||
    file.status.transcriptionStatus === FileTranscriptionStatus.Completed
  ) {
    return FileDisplayStatus.COMPLETED;
  }

  return FileDisplayStatus.UPLOADED;
};

export const isFileProcessing = (fileAsset: Pick<FileAsset, 'status'>) => {
  return [
    FileDisplayStatus.OCR_PENDING,
    FileDisplayStatus.OCR_IN_PROGRESS,
    FileDisplayStatus.INDEXING_PENDING,
    FileDisplayStatus.INDEXING_IN_PROGRESS,
    FileDisplayStatus.SPLITTING,
  ].includes(getFileDisplayStatus(fileAsset));
};

export const isFileFailed = (fileAsset: Pick<FileAsset, 'status'>) => {
  return [FileDisplayStatus.FAILED, FileDisplayStatus.CORRUPTED].includes(getFileDisplayStatus(fileAsset));
};

export const isFileUploading = (fileAsset: Pick<FileAsset, 'status'>) => {
  return fileAsset.status.uploadStatus === FileUploadStatus.UPLOADING;
};

export const isFileUploaded = (fileAsset: Pick<FileAsset, 'status'>) => {
  return fileAsset.status.uploadStatus === FileUploadStatus.UPLOADED;
};

export const isFileRetriable = (fileAsset: Pick<FileAsset, 'status'>) => {
  return [FileDisplayStatus.UPLOADED, FileDisplayStatus.OCR_FAILED, FileDisplayStatus.CORRUPTED].includes(
    getFileDisplayStatus(fileAsset),
  );
};

export const isThereFileWithStatus = (
  assetItems: (FileAsset | CollectionAsset)[],
  fileStatusCheckFunction: (fileAsset: Pick<FileAsset, 'status'>) => boolean,
) => {
  for (const assetItem of assetItems) {
    if (assetItem.type === AssetType.FILE && fileStatusCheckFunction(assetItem)) {
      return true;
    }

    if (
      assetItem.type === AssetType.COLLECTION &&
      assetItem.files &&
      isThereFileWithStatus(assetItem.files, fileStatusCheckFunction)
    ) {
      return true;
    }
  }

  return false;
};

export const isThereProcessingFile = (assetItems: (FileAsset | CollectionAsset)[]) => {
  return isThereFileWithStatus(assetItems, isFileProcessing);
};

export const isThereFailedFile = (assetItems: (FileAsset | CollectionAsset)[]) => {
  return isThereFileWithStatus(assetItems, isFileFailed);
};

export const isThereUploadingFile = (assetItems: (FileAsset | CollectionAsset)[]) => {
  return isThereFileWithStatus(assetItems, isFileUploading);
};

export const convertBrowserFileToAsset = (
  file: File,
  uploadStatus = FileUploadStatus.UPLOADING,
  id?: string,
): FileAsset => {
  const formattedFileDate = formatDateTime(new Date());

  return {
    id: id ?? uuidv4(),
    url: '',
    type: AssetType.FILE,
    size: file.size,
    name: file.name,
    mimeType: file.type,
    isCombinedCfile: false,
    isDraft: true,
    createdAt: formattedFileDate,
    updatedAt: formattedFileDate,
    viewerCanUpdate: false,
    viewerCanDelete: false,
    status: {
      uploadStatus,
      indexingStatus: FileIndexingStatus.NotStarted,
      ocrStatus: FileOcrStatus.NotStarted,
      transcriptionStatus: FileTranscriptionStatus.NotStarted,
    },
  };
};

export const convertToBrowserFile = (file: File): BrowserFile => {
  return { id: uuidv4(), originalFile: file };
};

export const getDefaultUploadOptions = (): UploadFileOptions => {
  return {
    shouldOcr: true,
    ocrMethod: OcrMethod.Simple,
    shouldIndex: false,
    chunkCharSize: 1000,
    chunkCharOverlap: 150,
  };
};

export const getFileTextExtractionProgress = (file: Pick<FileAsset, 'textExtractionProcessingDetails'>) => {
  if (!file.textExtractionProcessingDetails) return null;

  const { partsProcessed, partsTotal } = file.textExtractionProcessingDetails.processingProgress;

  return partsProcessed / partsTotal;
};

export const flatFileAssetsFromAssetItems = (assetItems: AssetItem[]) => {
  const fileAssets: FileAsset[] = [];

  for (const assetItem of assetItems) {
    if (assetItem.type === AssetType.FILE) {
      fileAssets.push(assetItem);
    }

    if (assetItem.type === AssetType.COLLECTION) {
      if (assetItem.files) fileAssets.push(...assetItem.files);
    }
  }

  return fileAssets;
};

export const getUploadingFiles = (fileAssets: FileAsset[]) => {
  return fileAssets.filter((file) => file.status.uploadStatus === FileUploadStatus.UPLOADING);
};

export const getUploadFailedFiles = (fileAssets: FileAsset[]) => {
  return fileAssets.filter((file) => file.status.uploadStatus === FileUploadStatus.FAILED);
};

export const getUploadedFiles = (fileAssets: FileAsset[]) => {
  return fileAssets.filter((file) => file.status.uploadStatus === FileUploadStatus.UPLOADED);
};

export const estimateFileUploadTime = (fileAsset: FileAsset) => {
  const fileSizeInKB = fileAsset.size / 1024;

  // Each 100kB takes about 0.5 seconds to upload
  const uploadTimeInSeconds = (fileSizeInKB / 100) * 0.5;

  return uploadTimeInSeconds;
};

export const estimateUploadTimeForFiles = (fileAssets: FileAsset[]) => {
  const initialUploadPendingTimeInSeconds = 5;
  const totalUploadTimeInSeconds =
    Math.max(
      ...fileAssets
        .filter((fileAsset) => fileAsset.status.uploadStatus === FileUploadStatus.UPLOADING)
        .map(estimateFileUploadTime),
    ) +
    initialUploadPendingTimeInSeconds +
    fileAssets.filter(isFileUploading).length * 0.5;

  return totalUploadTimeInSeconds;
};

export const estimateFileOcrTime = (fileAsset: FileAsset) => {
  const fileSizeInKB = fileAsset.size / 1024;

  // Each 100kB takes about 5 seconds to be processed
  const ocrTimeInSeconds = (fileSizeInKB / 100) * 5;

  return ocrTimeInSeconds;
};

export const estimateOcrTimeForFiles = (fileAssets: FileAsset[]) => {
  const initialUploadPendingTimeInSeconds = 30;
  const totalUploadTimeInSeconds =
    Math.max(...fileAssets.map(estimateFileOcrTime)) + initialUploadPendingTimeInSeconds + fileAssets.length * 0.5;

  return totalUploadTimeInSeconds;
};

export const getFileOcrMessage = (processedFiles: FileAsset[], processingFiles: FileAsset[], allFiles: FileAsset[]) => {
  const timeLeft = formatTime(estimateOcrTimeForFiles(processingFiles));

  return [`${processedFiles.length}/${allFiles.length} completed`, `(~${timeLeft})`].join(' ');
};

export const getDuplicatedFileAssets = (fileAssets: FileAsset[], newFiles: File[]) => {
  return fileAssets.filter((fileAsset) => newFiles.some((newFile) => newFile.name === fileAsset.name));
};

export const replaceSpecialFilenameCharacters = (filename: string) => {
  return filename.replace(/&#38[_|;]/g, '&');
};

export const getNewFileAssets = (fileAssets: FileAsset[], selectedFiles: File[]) => {
  const existingFileNames = fileAssets.map((fileAsset) => fileAsset.name);

  const newFiles = selectedFiles.filter(
    (newFile) => !existingFileNames.includes(replaceSpecialFilenameCharacters(newFile.name)),
  );

  const newFileAssets = newFiles
    .map((newFile) => convertBrowserFileToAsset(newFile))
    .map((fileAsset) => ({ ...fileAsset, name: replaceSpecialFilenameCharacters(fileAsset.name) }));

  return { newFiles, newFileAssets };
};

export const getTotalPagesFromFiles = (fileAssets: FileAsset[]) => {
  return fileAssets.reduce((acc, file) => {
    return acc + (file.metadata?.totalPages || 0);
  }, 0);
};

export const getAssetIcon = (assetItem: Pick<FileAsset, 'mimeType' | 'type'> | CollectionAsset) => {
  if (assetItem.type === AssetType.COLLECTION) return FolderIcon;

  if (assetItem.mimeType === 'application/pdf') return PDFIcon;

  return PhotoIcon;
};

export const getPdfFilename = (fileAsset: FileAsset, clientDisplayName?: string) => {
  return [
    removeAllSpaces(clientDisplayName || ''),
    fileAsset.metadata?.pageAIMetadata?.indexNumber.replace(/-/g, '') || '',
    removeAllSpaces(fileAsset.metadata?.pageAIMetadata?.vaFileTypeName || ''),
    fileAsset.createdAt?.slice(0, 10).replace(/-/g, ''),
  ]
    .filter(truthy)
    .join('_');
};

export const printPdf = (src: Blob | MediaSource) => {
  const iframe = document.createElement('iframe');

  iframe.src = window.URL.createObjectURL(src);

  iframe.onload = () => {
    const pdfFrame = iframe.contentWindow;

    if (!pdfFrame) return;

    const onAfterPrint = () => {
      iframe.remove();

      window.removeEventListener('focus', onAfterPrint);
    };

    window.addEventListener('focus', onAfterPrint);

    pdfFrame.print();
  };

  document.body.appendChild(iframe);
};
