import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ActionIcon, Box, Flex, ScrollArea, Skeleton, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconNewsOff, IconSearch } from '@tabler/icons-react';

import { singularOrPlural } from '@/shared/utils/string';
import { ClientFileSearchPanelProps } from './ClientFileSearchPanel.types';
import { ClientFileSearchTable } from '../../files/ClientFileSearchTable';
import { useSearchClientFiles } from '@/pageAI/hooks/files/useSearchClientFiles';
import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';
import { TableNavigationHint } from '../../files/TableNavigationHint';
import { DataEmptyPlaceholder } from '../DataEmptyPlaceholder';
import { FileFilters } from '../../files/FileFilters';
import { useFileSearchContext } from '@/pageAI/contexts/fileSearchContext';
import { useFileFilterSearchParams } from '@/pageAI/hooks/files/useFileFilterSearchParams';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { FileSearchResultQuickNavigation } from '../../files/search/FileSearchResultQuickNavigation';
import {
  confirmContentSearchEventBus,
  useContentSearchPage,
  useSearchWithSummary,
} from './ClientFileSearchPanel.utils';
import { FileSearchInitialPlaceholder } from '../../files/search/FileSearchInitialPlaceholder';
import { posthog } from '@/shared/plugins/posthog';
import { useConditionTermSearchFeatureFlag } from '@/pageAI/hooks/featureFlags/useConditionTermSearchFeatureFlag';
import { FileSearchPagination } from '../../files/search/FileSearchPagination';
import { useUnifiedScrollPosition } from '@/pageAI/hooks/unified/useUnifiedScrollPosition/useUnifiedScrollPosition';
import { UnifiedTab } from '@/pageAI/services/medicalConditions';
import { ConditionSearchTermContainer } from '../../medicalConditions/ConditionSearchTermContainer';
import { useElementRect } from '@/shared/hooks/dom/useElementRect';

const ClientFileSearchPanelBase = ({ client }: ClientFileSearchPanelProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { fileGroups, fileTypes } = useFileSearchContext();
  useFileFilterSearchParams({ fileGroups, fileTypes });
  const { enabled: conditionTermSearchFeatureEnabled } = useConditionTermSearchFeatureFlag();
  const scrollAreaRef = useUnifiedScrollPosition(UnifiedTab.CONTENT_SEARCH);
  const { ref: headerRef, rect: headerRect } = useElementRect();

  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const form = useForm({
    initialValues: {
      searchValue: searchParams.get('query') || '',
    },
  });
  const [query, setQuery] = useState(form.values.searchValue);
  const [withSummary, setWithSummary] = useSearchWithSummary();
  const [page, setPage] = useContentSearchPage();

  const {
    results = [],
    isLoading,
    isError,
    pageInfo,
    totalPages,
    totalCount,
  } = useSearchClientFiles({
    clientId: client.id,
    query,
    pageIndex: page - 1,
    fileNames: fileTypes?.length ? fileTypes : undefined,
    withSummary,
  });

  const confirmSearch = useCallback(
    (override?: string, withSummary = false) => {
      let newSearchParams = setWithSummary(withSummary);

      // Disabling withSummary for now (no AI Summary)
      newSearchParams = setPage(1);

      const newQuery = typeof override === 'string' ? override : form.values.searchValue;

      setQuery(newQuery);
      newSearchParams.set('query', newQuery);
      setSearchParams(newSearchParams, { replace: true });
    },
    [form.values.searchValue, setSearchParams, setWithSummary, setPage],
  );

  const handleSubmit = useCallback(() => {
    posthog.capture('[Search] Search file content', {
      clientId: client.id,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
      query: form.values.searchValue,
    });

    confirmSearch();
  }, [confirmSearch, form.values.searchValue, client.id, client.firstName, client.lastName]);

  useEffect(() => {
    const unsubscribe = confirmContentSearchEventBus.subscribe(({ query, withSummary }) => {
      form.setFieldValue('searchValue', query);

      confirmSearch(query, withSummary);
    });

    return () => {
      unsubscribe();
    };
  }, [confirmSearch, form]);

  const renderBody = () => {
    if (isError) {
      return (
        <Flex align="center" justify="center" sx={{ width: '100%', height: '100%' }}>
          <ErrorPlaceholder />
        </Flex>
      );
    }

    if (isLoading) {
      return (
        <Flex align="center" justify="center" sx={{ width: '100%', height: '100%' }}>
          <LoadingPlaceholder
            title="Searching..."
            description={`Page is looking through ${
              client.fileCollections.nodes[0].files?.nodes.length || 0
            } files of ${getClientDisplayName(client)}.`}
          />
        </Flex>
      );
    }

    if (!results.length) {
      return (
        <Flex direction="column" gap="xs" pb="md" sx={{ height: '100%' }}>
          <Flex align="center" justify="center" sx={{ flexGrow: 1 }}>
            {query ? (
              <DataEmptyPlaceholder
                icon={<IconNewsOff size={80} />}
                title="No results"
                description="Nothing was found. Please try with a different input."
              />
            ) : (
              <FileSearchInitialPlaceholder />
            )}
          </Flex>

          <FileSearchPagination totalPages={totalPages} hasNextPage={pageInfo?.hasNextPage} />
        </Flex>
      );
    }

    return (
      <Box px={32} pb={16}>
        <ClientFileSearchTable results={results} query={query} page={page} />

        <FileSearchPagination
          totalPages={totalPages}
          numberOfResults={results.length}
          hasNextPage={pageInfo?.hasNextPage}
        />
      </Box>
    );
  };

  const searchInput = (
    <TextInput
      {...form.getInputProps('searchValue')}
      w={280}
      placeholder="Enter keywords..."
      rightSection={
        <ActionIcon onClick={() => handleSubmit()} variant="filled" color="brand.4">
          <IconSearch size={16} />
        </ActionIcon>
      }
    />
  );

  return (
    <Flex direction="column" ref={tableContainerRef} sx={{ gap: 4, width: '100%', height: '100%' }}>
      <Flex
        ref={headerRef}
        align="center"
        justify="space-between"
        gap="xs"
        py={12}
        px={32}
        sx={(theme) => ({ position: 'sticky', top: 0, zIndex: 101, background: theme.white, flexWrap: 'wrap' })}
      >
        <Flex align="center" gap="xs">
          <Flex align="center" gap={2} sx={{ height: 36 }}>
            <Flex align="center" justify="center" ml={-4}>
              <FileSearchResultQuickNavigation results={results} />
            </Flex>

            <Text fw={600} color="dark.4" sx={{ whiteSpace: 'nowrap' }}>
              Search Results
            </Text>
          </Flex>

          {!conditionTermSearchFeatureEnabled && (
            <>
              <Flex sx={(theme) => ({ width: 4, height: 4, background: theme.colors.dark[4], borderRadius: '50%' })} />

              {isLoading ? (
                <Skeleton width={64} height={22} />
              ) : (
                <Text fw={500} fz="0.875rem" color="dark.3">
                  <>
                    {totalCount}&nbsp;
                    {singularOrPlural('result', 'results')(totalCount)}
                  </>
                </Text>
              )}
            </>
          )}

          <TableNavigationHint />
        </Flex>

        <Flex align="center" justify="flex-end" gap={4} sx={{ flexGrow: 1 }}>
          <FileFilters client={client} />

          <form onSubmit={form.onSubmit(handleSubmit)}>{searchInput}</form>
        </Flex>
      </Flex>

      <ScrollArea
        h={`calc(100vh - ${56 + headerRect.height}px)`}
        ref={scrollAreaRef}
        sx={{
          '.ghost-ScrollArea-viewport': {
            '> div': {
              height: '100%',
            },
          },
        }}
      >
        <Flex direction="column" sx={{ height: '100%' }}>
          <Flex mb="xs" px={32}>
            {conditionTermSearchFeatureEnabled && <ConditionSearchTermContainer />}
          </Flex>

          {renderBody()}
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

export const ClientFileSearchPanel = memo(ClientFileSearchPanelBase);
