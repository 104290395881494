import { graphql } from '@/pageAI/gql';

export const createCreditPurchaseSessionMutation = graphql(/* GraphQL */ `
  mutation CreateCreditPurchaseSession($input: CreateCreditPurchaseSessionInput!) {
    createCreditPurchaseSession(input: $input) {
      url
    }
  }
`);

export const createBillingSetupSessionMutation = graphql(/* GraphQL */ `
  mutation CreateBillingSetupSession($input: CreateBillingSetupSessionInput!) {
    createBillingSetupSession(input: $input) {
      url
    }
  }
`);

export const invoicesQuery = graphql(/* GraphQL */ `
  query Invoices($id: IDV2!) {
    organization(id: $id) {
      invoices(first: 1000) {
        nodes {
          id
          status
          description
          amountDue
          amountPaid
          tax
          total
          subtotal
          dueDate
          periodStart
          periodEnd
          pdfUrl
          hostedUrl
          collectionMethod
          number
          createdAt
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  }
`);
