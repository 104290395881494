export const parseDateOnly = (undeterminedDate: Date | string) => {
  const dateObject = new Date(undeterminedDate);

  if (!isDateObjectValid(dateObject)) return new Date();

  dateObject.setTime(dateObject.getTime() + dateObject.getTimezoneOffset() * 60 * 1000);

  return dateObject;
};

export const formatDate = (underterminedDate: Date | string, options?: Intl.DateTimeFormatOptions) => {
  const dateObject = new Date(underterminedDate);

  return new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', ...options }).format(dateObject);
};

export const formatShortDate = (underterminedDate: Date | string) => {
  const dateObject = new Date(underterminedDate);

  return dateObject.toLocaleDateString('en-CA');
};

export const formatDateTime = (underterminedDate: Date | string, options?: Intl.DateTimeFormatOptions) => {
  const dateObject = new Date(underterminedDate);

  return new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'medium', ...options }).format(dateObject);
};

export const formatDateTimeUTC = (underterminedDate: Date | string) => {
  const dateObject = new Date(underterminedDate).toUTCString();

  return dateObject;
};

export const areDatesWithinSameDay = (dateA: Date, dateB: Date) => {
  return (
    dateA.getDate() === dateB.getDate() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getFullYear() === dateB.getFullYear()
  );
};

export const groupObjectsByDate = <T extends Record<string, unknown>>(objects: T[], dateProperty = 'createdAt') => {
  const currentDate = new Date();

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);

  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(currentDate.getMonth() - 2);

  const previous30DaysGroup: T[] = [];
  const twoMonthsAgoGroup: T[] = [];
  const olderGroup: T[] = [];

  objects.forEach((object) => {
    if (typeof object[dateProperty] !== 'string') return;

    const createdAt = new Date(object[dateProperty] as string);

    if (createdAt >= thirtyDaysAgo) return previous30DaysGroup.push(object);

    if (createdAt >= twoMonthsAgo) return twoMonthsAgoGroup.push(object);

    olderGroup.push(object);
  });

  return {
    previous30DaysGroup,
    twoMonthsAgoGroup,
    olderGroup,
  };
};

export const sortDate = (dateA: Date | string, dateB: Date | string) => {
  return new Date(dateA) > new Date(dateB) ? 1 : -1;
};

export const isDateObjectValid = (date: Date) => date.toString() !== 'Invalid Date';

export const getEndOfDay = (date: Date) => {
  const endOfDay = new Date(date);

  endOfDay.setHours(23, 59, 59, 999);

  return endOfDay;
};

export const getStartAndEndOfYear = (year: Date) => {
  const startOfYear = new Date(year);
  const endOfYear = new Date(year);

  startOfYear.setMonth(0, 1);
  startOfYear.setHours(0, 0, 0, 0);

  endOfYear.setMonth(11, 31);
  endOfYear.setHours(23, 59, 59, 999);

  return [startOfYear, endOfYear];
};

export const getStartAndEndOfMonth = (month: Date) => {
  const startOfMonth = new Date(month);
  const endOfMonth = new Date(month);

  startOfMonth.setDate(1);
  startOfMonth.setHours(0, 0, 0, 0);

  endOfMonth.setMonth(endOfMonth.getMonth() + 1, 0);
  endOfMonth.setHours(23, 59, 59, 999);

  return [startOfMonth, endOfMonth];
};

export const getMonthText = (month: number | string) => {
  const monthAsNumber = Number(month);

  if (Number.isNaN(monthAsNumber) || monthAsNumber < 1 || monthAsNumber > 12) return '';

  const monthText = new Date(2021, monthAsNumber - 1, 1).toLocaleString('default', { month: 'long' });

  return monthText;
};

export const getDayDiff = (dateA: Date, dateB: Date) => {
  const diff = dateA.getTime() - dateB.getTime();

  return Math.ceil(diff / (1000 * 60 * 60 * 24));
};
