import { graphql } from '@/pageAI/gql';

export const usageHistoryQuery = graphql(/* GraphQL */ `
  query UsageHistory($id: IDV2!, $first: Int, $after: String, $from: Time, $to: Time) {
    organization(id: $id) {
      id
      creditTransactions(first: $first, after: $after, from: $from, to: $to) {
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
        totalCount
        nodes {
          id
          amount
          balance
          createdAt
          description
          type
          client {
            id
            firstName
            lastName
          }
          order {
            id
            quantity
            status
            totalAmount
            unitPrice
            invoice {
              id
              status
              description
              amountDue
              amountPaid
              tax
              total
              subtotal
              dueDate
              periodStart
              periodEnd
              pdfUrl
              hostedUrl
              collectionMethod
              number
              createdAt
            }
            createdAt
          }
          createdBy {
            id
            firstName
            lastName
            email
            avatarUrl
            phone
            role
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`);
