import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export type PageName = 'settings' | 'chat' | string;

export const usePageMatch = () => {
  const location = useLocation();

  const isInPage = useCallback(
    (pageName: PageName) => {
      // TODO: Fix this hacky way of checking if we are on the usage-billing page
      if (location.pathname.includes('usage-billing')) {
        return pageName.includes('usage-billing');
      }

      return location.pathname.startsWith(pageName);
    },
    [location.pathname],
  );

  return { isInPage };
};
