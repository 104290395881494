import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, Flex, Modal, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { EventBus } from 'native-pubsub';

import { PricingCard } from '@/shared/designSystem/PricingCard';
import { useCreateCreditPurchaseSession } from '@/pageAI/hooks/billing/useCreateCreditPurchaseSession';
import { useCreateBillingSetupSession } from '@/pageAI/hooks/billing/useCreateBillingSetupSession';
import { useOrganization } from '@/pageAI/hooks/organizations/useOrganization';

interface PurchaseModalProps {}

const openPurchaseModalEventBus = new EventBus();

export const openPurchaseModal = () => {
  openPurchaseModalEventBus.publish({});
};

const PurchaseModalBase = (props: PurchaseModalProps) => {
  const { organizationId } = useParams();
  const { organization } = useOrganization(organizationId);
  const { createCreditPurchaseSession, isLoading: isCreatingCreditPurchaseSession } = useCreateCreditPurchaseSession();
  const { createBillingSetupSession, isLoading: isCreatingBillingSetupSession } = useCreateBillingSetupSession();

  const [opened, { open, close }] = useDisclosure(false);
  const [autoRenewal, setAutoRenewal] = useState(false);

  const handleSubscribe = async () => {
    if (!organizationId) return;

    const response = await createBillingSetupSession({
      organizationId,
      cancelUrl: window.location.href,
      successUrl: window.location.href,
    });

    window.open(response.createBillingSetupSession?.url);
  };

  const handlePurchase = async (numberOfCredits: number) => {
    if (!organizationId) return;

    const response = await createCreditPurchaseSession({
      organizationId,
      quantity: numberOfCredits,
      cancelUrl: window.location.href,
      successUrl: window.location.href,
      autoRenewal,
    });

    window.open(response.createCreditPurchaseSession?.url);
  };

  const renderContent = () => {
    return (
      <Flex direction="column" align="center" sx={{ width: '100%' }}>
        <Flex
          align="center"
          justify="center"
          gap="xl"
          sx={{
            '.pricing-card': {
              minWidth: 400,
              maxWidth: 640,
            },
            '.monthly-plan': {
              minHeight: 560,
            },
          }}
        >
          <PricingCard
            title="Pay as you go"
            description="Flexible plan - billed at the end of each month"
            features={[
              'Unlimited clients',
              'Unlimited usage',
              'Comprehensive case timelines',
              'Summaries for all conditions',
              'Powerful content search',
            ]}
            price="$175"
            priceSuffix="/credit*"
            creditsSelectable={false}
            buttonText={organization?.subscription ? 'Already subscribed' : 'Subscribe'}
            onPurchase={handleSubscribe}
            loading={isCreatingBillingSetupSession}
            disabled={!!organization?.subscription}
          />

          <PricingCard
            title="Buy credit bundles"
            description="For your entire organization - save per credit"
            onPurchase={handlePurchase}
            features={[
              'Discounted pricing',
              'Personalized onboarding',
              'Complete Page functionality',
              'Credits never expire',
            ]}
            price={
              <Text component="span">
                <Text component="span" fz="1rem">
                  As low as
                </Text>{' '}
                $149
              </Text>
            }
            priceSuffix="/credit*"
            inverse
            buttonText="Select bundle"
            loading={isCreatingCreditPurchaseSession}
            aboveButtonSection={
              <Checkbox
                mt="xl"
                sx={(theme) => ({
                  '.ghost-Checkbox-label': {
                    color: theme.white,
                  },
                })}
                checked={autoRenewal}
                onChange={(event) => setAutoRenewal(event.currentTarget.checked)}
                color="teal"
                label="Automatically replenish my credits when low (2 credits)"
              />
            }
          />
        </Flex>

        <Text size="sm" color="gray.6" mt="md">
          *1 credit = 1 client on Page (including incremental uploads and summaries)
        </Text>
      </Flex>
    );
  };

  useEffect(() => {
    const unsubscribe = openPurchaseModalEventBus.subscribe(() => {
      open();
    });

    return unsubscribe;
  }, [open]);

  return (
    <Modal fullScreen opened={opened} onClose={close}>
      <Flex direction="column">
        <Text align="center" size="2rem" color="dark" weight={700} mb="xl">
          Choose a plan
          <Text size="sm" fw={400} color="gray.6" mt="xs">
            Select an option that fits your firm&apos;s needs.
            <br />
            Pay as you go or buy credit bundles for discounted pricing.
          </Text>
        </Text>

        {renderContent()}
      </Flex>
    </Modal>
  );
};

export const PurchaseModal = memo(PurchaseModalBase);
