import { graphql } from '@/pageAI/gql';

export const createClientMutation = graphql(/* GraphQL */ `
  mutation CreateClient($input: CreateClientInput!) {
    createClient(input: $input) {
      id
      firstName
      lastName
      bio
      overview
      caseTimelineStatus
      summaryByConditionStatus
      viewerCanArchive
      viewerCanDelete
      viewerCanGenerateTimeline
      viewerCanShare
      viewerCanUnarchive
      viewerCanUpdate
      viewerCanUploadFiles
      creator {
        id
        firstName
        lastName
        email
        avatarUrl
        phone
        role
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`);

export const deleteClientMutation = graphql(/* GraphQL */ `
  mutation DeleteClient($id: ID!) {
    deleteClient(id: $id)
  }
`);

export const updateClientMutation = graphql(/* GraphQL */ `
  mutation UpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      id
      firstName
      lastName
      bio
      overview
      caseTimelineStatus
      summaryByConditionStatus
      viewerCanArchive
      viewerCanDelete
      viewerCanGenerateTimeline
      viewerCanShare
      viewerCanUnarchive
      viewerCanUpdate
      viewerCanUploadFiles
      metadata {
        key
        value
      }
      creator {
        id
        firstName
        lastName
        email
        avatarUrl
        phone
        role
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`);

export const sharedClientsQuery = graphql(/* GraphQL */ `
  query SharedClients($first: Int, $after: String, $query: String, $assigneeIds: [ID!], $orderBy: ClientOrder) {
    sharedClients(first: $first, after: $after, query: $query, assigneeIds: $assigneeIds, orderBy: $orderBy) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        id
        firstName
        lastName
        bio
        overview
        caseTimelineStatus
        summaryByConditionStatus
        viewerCanArchive
        viewerCanDelete
        viewerCanGenerateTimeline
        viewerCanShare
        viewerCanUnarchive
        viewerCanUpdate
        viewerCanUploadFiles
        assignee {
          id
          firstName
          lastName
          email
          avatarUrl
          phone
          role
          createdAt
          updatedAt
        }
        creator {
          id
          firstName
          lastName
          email
          avatarUrl
          phone
          role
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`);

export const clientQuery = graphql(/* GraphQL */ `
  query Client($id: ID!) {
    client(id: $id) {
      id
      firstName
      lastName
      bio
      overview
      latestCombinedRating
      caseTimelineStatus
      summaryByConditionStatus
      metadata {
        key
        value
      }
      permissions(first: 9999) {
        nodes {
          id
          email
          displayName
          role
          type
          createdAt
          updatedAt
        }
        totalCount
      }
      viewerCanArchive
      viewerCanDelete
      viewerCanGenerateTimeline
      viewerCanShare
      viewerCanUnarchive
      viewerCanUpdate
      viewerCanUploadFiles
      assignee {
        id
        firstName
        lastName
        email
        avatarUrl
        phone
        role
        createdAt
        updatedAt
      }
      creator {
        id
        firstName
        lastName
        email
        avatarUrl
        phone
        role
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      fileCollections(first: 1000) {
        nodes {
          id
          name
          createdAt
          updatedAt
          files(first: 9999) {
            nodes {
              id
              name
              mimeType
              url
              size
              viewerCanUpdate
              viewerCanDelete
              isImportant
              isCombinedCfile
              status {
                indexingStatus
                ocrStatus
                splitStatus
                transcriptionStatus
                uploadStatus
              }
              textExtractionProcessingDetails {
                processingProgress {
                  partsProcessed
                  partsTotal
                }
                processingStatus
              }
              metadata {
                totalPages
                pageAIMetadata {
                  isVA
                  indexNumber
                  fileExtension
                  vaFileTypeName
                  eventTypeClassifications
                  cFileTypes
                  fileEventDate
                }
              }
              createdAt
              updatedAt
            }
            totalCount
          }
        }
      }
    }
  }
`);

export const clientsQuery = graphql(/* GraphQL */ `
  query Clients($first: Int, $after: String, $query: String, $assigneeIds: [ID!], $orderBy: ClientOrder) {
    viewer {
      organizations(first: 1) {
        nodes {
          id
          name
          avatarUrl
          createdAt
          updatedAt
          clients(first: $first, after: $after, query: $query, assigneeIds: $assigneeIds, orderBy: $orderBy) {
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            nodes {
              id
              firstName
              lastName
              bio
              overview
              caseTimelineStatus
              summaryByConditionStatus
              viewerCanArchive
              viewerCanDelete
              viewerCanGenerateTimeline
              viewerCanShare
              viewerCanUnarchive
              viewerCanUpdate
              viewerCanUploadFiles
              assignee {
                id
                firstName
                lastName
                email
                avatarUrl
                phone
                role
                createdAt
                updatedAt
              }
              creator {
                id
                firstName
                lastName
                email
                avatarUrl
                phone
                role
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            totalCount
          }
        }
        totalCount
      }
      id
    }
  }
`);

export const createClientPermissionMutation = graphql(/* GraphQL */ `
  mutation CreateClientPermission($input: CreateClientPermissionInput!) {
    createClientPermission(input: $input) {
      id
      email
      displayName
      role
      type
      createdAt
      updatedAt
    }
  }
`);

export const updateClientPermissionMutation = graphql(/* GraphQL */ `
  mutation UpdateClientPermission($input: UpdateClientPermissionInput!) {
    updateClientPermission(input: $input) {
      id
      email
      displayName
      role
      type
      createdAt
      updatedAt
    }
  }
`);

export const deleteClientPermissionMutation = graphql(/* GraphQL */ `
  mutation DeleteClientPermission($id: ID!) {
    deleteClientPermission(id: $id)
  }
`);

export const assignClientMutation = graphql(/* GraphQL */ `
  mutation AssignClient($input: AssignClientInput!) {
    assignClient(input: $input) {
      id
      assignee {
        id
        firstName
        lastName
        email
        avatarUrl
        phone
        role
        createdAt
        updatedAt
      }
    }
  }
`);

export const getClientOperationSubscriptionQuery = () => {
  return `subscription ClientOperationSubscription {
    event {
      ... on ClientCreated {
        client {
          id
          firstName
          lastName
          bio
          overview
          caseTimelineStatus
          summaryByConditionStatus
          viewerCanArchive
          viewerCanDelete
          viewerCanGenerateTimeline
          viewerCanShare
          viewerCanUnarchive
          viewerCanUpdate
          viewerCanUploadFiles
          creator {
            id
            firstName
            lastName
            email
            avatarUrl
            phone
            role
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        issuedAt
        issuingPrincipal {
          ... on User {
            id
            firstName
            lastName
            email
            avatarUrl
            phone
            role
            createdAt
            updatedAt
          }
        }
      }
      ... on ClientDeleted {
        client {
          id
          firstName
          lastName
          bio
          overview
          caseTimelineStatus
          summaryByConditionStatus
          viewerCanArchive
          viewerCanDelete
          viewerCanGenerateTimeline
          viewerCanShare
          viewerCanUnarchive
          viewerCanUpdate
          viewerCanUploadFiles
          creator {
            id
            firstName
            lastName
            email
            avatarUrl
            phone
            role
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        issuedAt
        issuingPrincipal {
          ... on User {
            id
            firstName
            lastName
            email
            avatarUrl
            phone
            role
            createdAt
            updatedAt
          }
        }
      }
    }
  }`;
};
