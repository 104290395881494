import { useCallback } from 'react';
import { EventBus } from 'native-pubsub';

import { GraphQLRequestFunction, graphQLClient } from '@/shared/lib/graphql';
import { useAccessToken } from '@/shared/hooks/auth/useAccessToken';
import { useLogout } from '../../auth/useLogout';
import { useNotifications } from '../../notifications/useNotifications';
import { isThereLoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { Sentry } from '@/shared/plugins/sentry';

export const networkErrorEventBus = new EventBus();

export interface NetworkError {
  response: {
    error: string;
    status: number;
    headers: Record<string, unknown>;
  };
}

export const isNetworkError = (error: unknown): error is NetworkError => {
  return !!(error as NetworkError).response;
};

export const useGraphQLRequest = () => {
  const { logout } = useLogout();
  const { getAccessTokenSilently } = useAccessToken();
  const { notify } = useNotifications();

  const request = useCallback(
    async (...args: Parameters<typeof graphQLClient.request>) => {
      try {
        try {
          const token = await getAccessTokenSilently();

          graphQLClient.setHeader('Authorization', `Bearer ${token}`);
        } catch (error) {
          console.error(error);
        }

        const response = await graphQLClient.request(...args);

        return response;
      } catch (error) {
        if ((error as Error).message.includes('Network request failed')) {
          notify('Network error', 'Page is having trouble connecting to the server.', 'red', 15000);

          if (isThereLoadingPlaceholder()) {
            networkErrorEventBus.publish({});
          }
        }

        if (isNetworkError(error)) {
          if (error.response.status === 401) {
            return logout();
          }
        }

        Sentry.captureException(error);

        throw error;
      }
    },
    [getAccessTokenSilently, logout, notify],
  );

  return { request: request as GraphQLRequestFunction };
};
