import { useCallback } from 'react';

import { Client } from '@/pageAI/@types';
import { setFilePreviewAsset, setPdfPreviewInitalPageIndex } from '@/shared/states/filePreview';
import { openRightSidebar } from '@/shared/states/layout';
import { extractFileInfoOfClientFromHref } from '@/pageAI/services/summaries';

export const useClientFileAnchorReference = (client?: Client | null) => {
  const handleOpenFileReferenceByHref = useCallback(
    (href: string) => {
      if (!client) return false;

      const { fileAsset, pageIndex } = extractFileInfoOfClientFromHref(client, href);

      if (!fileAsset?.metadata?.pageAIMetadata) return false;

      const { vaFileTypeName, indexNumber } = fileAsset.metadata.pageAIMetadata;

      openRightSidebar();
      setFilePreviewAsset(fileAsset, `[${indexNumber}] ${vaFileTypeName}`);
      setPdfPreviewInitalPageIndex(Number.isNaN(pageIndex) ? 0 : pageIndex);

      return fileAsset;
    },
    [client],
  );

  return { handleOpenFileReferenceByHref };
};
