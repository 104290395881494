import { memo } from 'react';
import { Flex, Text } from '@mantine/core';
import { IconBriefcase, IconCalendar, IconCurrencyDollar } from '@tabler/icons-react';

import { ImportantInfoItem } from '@/shared/designSystem/ImportantInfoRow';
import { ImportantInfoCard } from '@/shared/designSystem/ImportantInfoCard';
import { Organization } from '@/pageAI/@types';
import { getCurrentPlanInfo, getNextBillingInfo } from '@/pageAI/services/billing';
import { formatDateTime } from '@/shared/utils/date';
import { InvoicesTable } from '../InvoicesTable';
import { useUsageStore } from '@/pageAI/states/usage';
import { truthy } from '@/shared/utils/boolean';

interface BillingLayoutProps {
  organization: Organization;
}

const BillingLayoutBase = ({ organization }: BillingLayoutProps) => {
  const { planName, pricePerCredit } = getCurrentPlanInfo(organization);
  const { currentPeriodEnd } = getNextBillingInfo(organization);
  const creditsUsed = useUsageStore((state) => state.currentPeriodCreditsUsed);
  const estimatedTotalAmount = creditsUsed ? pricePerCredit * creditsUsed : undefined;

  const nextBillingCardItems: ImportantInfoItem[] = [
    {
      icon: IconBriefcase,
      label: 'Plan',
      value: planName,
      className: 'current-plan',
    },
    {
      icon: IconCurrencyDollar,
      label: 'Unit price',
      value: `$${pricePerCredit.toFixed(2)}`,
      subValue: 'per credit',
    },
    currentPeriodEnd
      ? {
          icon: IconCalendar,
          label: 'Next billing on',
          value: formatDateTime(new Date(currentPeriodEnd)),
        }
      : null,
    // typeof estimatedTotalAmount === 'number' && planName === 'Pay as you go'
    //   ? {
    //       icon: IconMoneybag,
    //       label: 'Estimated total amount',
    //       value: formatCurrency(estimatedTotalAmount),
    //       subValue: `for ${creditsUsed} ${singularOrPlural('credit', 'credits')(creditsUsed)}`,
    //     }
    //   : null,
  ].filter(truthy);

  const nextBillingCard = (
    <ImportantInfoCard
      items={nextBillingCardItems}
      className="next-billing-card"
      sx={(theme) => ({
        '.current-plan': {
          '.card-value': {
            color: theme.colors.dark[5],
          },
        },
        '.total-amount': {
          '.card-value': {
            color: theme.colors.teal[6],
          },
        },
      })}
    />
  );

  return (
    <Flex direction="column" sx={{ width: '100%' }} gap="xl">
      <Flex direction="column" gap="sm">
        <Flex gap="xs" align="center" justify="space-between">
          <Text fw={600} color="dark.4" sx={{ whiteSpace: 'nowrap' }}>
            Next Billing
          </Text>
        </Flex>

        {nextBillingCard}
      </Flex>

      <InvoicesTable organization={organization} />
    </Flex>
  );
};

export const BillingLayout = memo(BillingLayoutBase);
